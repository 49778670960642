/* eslint-disable no-console */
import axios from 'axios';
import './App.css';
/* eslint-disable no-unused-vars */
import MailIcon from './MailIcon';
import Footer from './Footer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState, useEffect } from 'react';
import Tippy from '@tippyjs/react';
/* eslint-enable no-unused-vars */
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { faBug } from '@fortawesome/free-solid-svg-icons';
import { useLocation } from 'react-router-dom';
import 'tippy.js/dist/tippy.css';

const settings = require('./config/settings.json');
const RECAPTCHA_SITE_KEY = settings.RECAPTCHA_SITE_KEY;
const SERVER_URL = settings.server_url  ? settings.server_url : 'http://localhost:9000';
const language = window.navigator.language || window.navigator.userLanguage;
const langKey = language ? language.split('-')[0] : 'de';
const iText = langKey === 'de' ? require('./translations/de.json') : require('./translations/en.json');

export function iTextWrapper (key, params, raw = false) {
  let _iText = iText[key];

  if (!_iText) {
    return key;
  }

  if (params && params.length > 0) {
    for(let i = 0; i < params.length; i++) {
      _iText = _iText.replace(`%p${i}`, params[i]);
    }
  }

  return !raw ? <div dangerouslySetInnerHTML={{__html: _iText}} /> : _iText;
}

export const App = () => {
  const [view, setView] = useState(0);
  const [hasError, setError] = useState(false);
  const [postReqFailed, setPostReqFailed] = useState(false);
  const [domain, setDomain] = useState('company');
  const [isLoading, setLoadingStatus] = useState(false);
  const [userEmail, setUserEmail] = useState('');
  const [btnDisabled, disableBtn] = useState(false);
  // Checkmark inputs:
  const [emailChecked, setEmailChecked] = useState(false);
  const [companyChecked, setCompanyChecked] = useState(false);
  const [workspaceChecked, setWorkspaceChecked] = useState(false);
  const [phoneNrChecked, setPhoneNrChecked] = useState(false);
  const [firstNameChecked, setFirstNameChecked] = useState(false);
  const [secondNameChecked, setSecondNameChecked] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  // Query params:
  const [plan, setPlan] = useState('premium');
  const [email, setEmail] = useState('');
  const [firstName, setFirstName] = useState('');
  const [secondName, setSecondName] = useState('');
  let newInputData = null;

  // https://hackernoon.com/how-to-use-componentwillmount-with-functional-components-in-react-fc143u9d
  // Load google reCaptcha script.
  useEffect(() => {
    const loadScriptByURL = (id, url, callback) => {
      const scriptExists = document.getElementById(id);

      if (!scriptExists) {
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = url;
        script.id = id;
        script.onload = function () {
          if (callback) callback();
        };
        document.body.appendChild(script);
      }

      if (scriptExists && callback) callback();
    };

    // load the script by passing the URL
    loadScriptByURL('recaptcha-key', `https://www.google.com/recaptcha/api.js?render=${RECAPTCHA_SITE_KEY}`, function () {
      console.log('Recaptcha enabled!');
    });
  }, []);

  const nextView = () => {
    setError(false);
    setView(view + 1);
  };

  const getLink = () => {
    return `${domain}.plantapulse.de`;
  };

  const checkInputForRegex = (nameValue) => {
    // We simply check for latin letters and allow Umlaute, accents, space and a dash.
    const nameRegexp = /^[A-Za-zÀ-ÖØ-öø-ÿ0-9- ]{1,}$/; // Fine tune this later if necessary.
    return nameRegexp.test(nameValue);
  };

  const validateName = (event, isFirstName, value) => {
    let nameValue;

    if (event) {
      event.preventDefault();
      const nameInput = event.currentTarget;
      nameValue = nameInput.value;
    } else {
      nameValue = value;
    }
    const isValidName = checkInputForRegex(nameValue);

    if (isFirstName) {
      setFirstName(nameValue);
      setFirstNameChecked(isValidName);
    } else {
      setSecondName(nameValue);
      setSecondNameChecked(isValidName);
    }
  };

  const validateCompany = (event) => {
    const companyInput = event.currentTarget;
    const isValid = checkInputForRegex(companyInput && companyInput.value);

    setCompanyChecked(isValid);
  };

  const validatePhoneNr = (event) => {
    event.preventDefault();
    const phoneInput = event.currentTarget;
    const phoneNr = phoneInput.value;
    const phoneNrRegexp = /^([+]{0,1}[\d-()/]{4,})$/;
    const isPhoneNr = phoneNrRegexp.test(phoneNr);

    setPhoneNrChecked(isPhoneNr ? phoneNr : false);
  };

  const validateEmail = (event, value) => {
    let emailAdress;

    if (event) {
      event.preventDefault();
      const emailInput = event.currentTarget;
      emailAdress = emailInput.value;
    } else {
      emailAdress = value;
    }

    setEmail(emailAdress);

    const emailRegexp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const isValidEmail = emailRegexp.test(emailAdress);

    setEmailChecked(isValidEmail);
  };

  // Todo: Need to check if the response is reliable when calling this function on every
  // input change.
  const checkWorkspaceAvailable = (event, value) => {
    let workspace;
    const workspaceInput = document.querySelector('#domain-input');

    if (event) {
      event.preventDefault();
      workspace = workspaceInput.value;
    } else {
      workspace = value;
    }
    setError(false);
    setWorkspaceChecked(false);
    // Only allow characters that are accepted for domainnames.
    // Todo: Rather than replacing invalid characters we should show an error message.
    const cleanWorkspace = workspace && workspace.trim().toLowerCase().replace(/[^a-zA-Z0-9-]/g, '');

    if (cleanWorkspace) {
      workspaceInput.value = cleanWorkspace;

      axios
        .post(`${SERVER_URL}/checkWorkspaceName`, { domain: cleanWorkspace })
        .then((res) => {
          if (res && res.data) {
            setWorkspaceChecked(!res.data.exists);

            if (!res.data.exists) {
              setDomain(res.data.hostname);
            } else {
              setDomain('company');
              setError(true);
            }
          }
        });
    } else {
      setDomain('company');
    }
  };

  const location = useLocation();

  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };

  const query = useQuery();

  useEffect(() => {
    // Optionally fill the inputs with values from the query params.
    const PLANS = ['base', 'premium', 'hybrid_base', 'hybrid_premium', 'hybrid_enterprise', 'project', 'project_enterprise'];
    const _plan = query.get('plan')?.toLowerCase();
    const _email = query.get('email')?.toLowerCase();
    const _workspace = query.get('workspace')?.toLowerCase();
    const _firstname = query.get('firstname')?.toLowerCase();
    const _lastname = query.get('lastname')?.toLowerCase();

    if (_email && typeof _email === 'string') {
      validateEmail(null, _email);
    }

    if (_workspace && typeof _workspace === 'string') {
      checkWorkspaceAvailable(null, _workspace);
    }

    if (_firstname && typeof _firstname === 'string') {
      validateName(null, true, _firstname);
    }

    if (_lastname && typeof _lastname === 'string') {
      validateName(null, false, _lastname);
    }

    if (_plan && PLANS.includes(_plan)) {
      setPlan(_plan);
    }
  }, [location]);

  const processData = () => {
    setLoadingStatus(true);

    if (newInputData) {
      axios
        .post(`${SERVER_URL}/processData`, newInputData)
        .then((res) => {
          if (res && res.data) {
            if (res.data.success) {
              nextView();
            } else {
              setPostReqFailed(res.data.statusCode);
            }
          }
          setLoadingStatus(false);
        });
    } else {
      console.error('Invalid inputs: ', newInputData);
    }
  };

  const verifyCaptchaRes = (token, majorPlan) => {
    axios
      .post(`${SERVER_URL}/verifyReCaptcha`, {token})
      .then(async(res) => {
        if (res.data.success && res.data.score >= 0.5) {
          if (majorPlan === 'project') {
            try {
              const response = await axios.post(`${SERVER_URL}/sendEmail`, newInputData);
              if (response.status === 200) {
                nextView();
              } else {
                alert('Ein Fehler ist aufgetreten. Bitte versuchen Sie es später erneut oder kontaktieren Sie uns direkt unter hotline@planta.de');
              }
            } catch (error) {
              console.error('Error sending email:', error);
              alert('Failed to send email');
            }
          } else {
            processData();
          }
        } else if (res.data['error-codes']) {
          console.error(res.data['error-codes']);
          // We use the recommended 0.5-threshhold (1 = human, 0 = bot).
        } else if (res.data.score < 0.5) {
          alert('Captcha failed.');
        }
      });
  };

  const handleDataSubmit = (event) => {
    event.preventDefault();
    const majorPlan = plan.split('_')[0];
    const _phoneNrChecked = majorPlan === 'hybrid' || majorPlan === 'project' ? phoneNrChecked : true;
    const _workspaceChecked = majorPlan !== 'project' ? workspaceChecked : true;
    const _disableBtn = !firstNameChecked || !secondNameChecked  || !_workspaceChecked  || !emailChecked || !_phoneNrChecked || !companyChecked;

    if (_disableBtn) {
      setSubmitted(true);
    } else {
      disableBtn(true);
      const domainInput = document.querySelector('#domain-input');
      const firstNameInput = document.querySelector('#firstName-input');
      const secondNameInput = document.querySelector('#secondName-input');
      const emailInput = document.querySelector('#email-input');
      const phoneInput = document.querySelector('#phone-input');
      const companyInput = document.querySelector('#company-input');
      // Skip phone input check when plan is for pulse stand-alone.
      let phoneNr = majorPlan === 'hybrid' || majorPlan === 'project' ? phoneInput && phoneInput.value.trim() : true;
      const domain = domainInput && domainInput.value.trim().replace(/[^a-zA-Z0-9-_]/g, '');
      const firstName = firstNameInput && firstNameInput.value.trim();
      const secondName = secondNameInput && secondNameInput.value.trim();
      const emailAdress = emailInput && emailInput.value.trim();
      const company = companyInput && companyInput.value.trim();
      const domainIsSet = majorPlan !== 'project' ? domain : true; // Project plans don't need a domain / pulse workspace.

      if (domainIsSet && firstName && secondName && phoneNr && company) {
        phoneNr = typeof phoneNr === 'string' ? phoneNr : '';
        const data = { domain, firstName, secondName, emailAdress, plan, phoneNr, lang: langKey, company };
        newInputData = data;
        setUserEmail(emailAdress);

        window.grecaptcha.ready(() => {
          window.grecaptcha.execute(RECAPTCHA_SITE_KEY, { action: 'submit'}).then((token) => {
            verifyCaptchaRes(token, majorPlan);
          });
        });
      } else {
        console.error('Invalid inputs: ', JSON.stringify({ domain, firstName, secondName }));
      }
    }
  };

  let header = <h1>PLANTA <i>pulse</i></h1>;
  let content;
  let headerText = <h1>PLANTA <i>pulse BASE</i></h1>;
  const checkedIcon = <FontAwesomeIcon icon={faCheck} />;
  const timesIcon = <FontAwesomeIcon icon={faTimes} />;
  const teleLabel = iTextWrapper('phoneLabel');
  const workspaceNameLinkLabel = iTextWrapper('workspaceNameLink', [], true);
  const workspaceLabel = hasError ? iTextWrapper('invalidWorkspace') : iTextWrapper('workspaceLabel', [domain !== 'company' ? domain : workspaceNameLinkLabel]);
  const workspaceLabelTooltip = hasError ? iTextWrapper('invalidWorkspace') : iTextWrapper('workspaceLabelTooltip', [domain !== 'company' ? domain : workspaceNameLinkLabel]);
  const popperOptions = {
    modifiers: [
      {
        name: 'flip',
        options: {
          fallbackPlacements: ['left', 'top'],
        },
      },
    ],
  };
  const majorPlan = plan.split('_')[0];

  if (view === 0) {
    if (plan === 'premium') {
      headerText = <h1>PLANTA <i>pulse PREMIUM</i></h1>;
    } else if (plan === 'hybrid_base') {
      headerText = <h1><i>HYBRID BASE</i></h1>;
    } else if (plan === 'hybrid_premium') {
      headerText = <h1><i>HYBRID PREMIUM</i></h1>;
    } else if (plan === 'hybrid_enterprise') {
      headerText = <h1><i>HYBRID ENTERPRISE</i></h1>;
    } else if (plan === 'project') {
      headerText = <h1>PLANTA Project</h1>;
    } else if (plan === 'project_enterprise') {
      headerText = <h1>PLANTA Enterprise</h1>;
    }
    header = headerText;
    const loadingIcon = <div className="fa-spin"><FontAwesomeIcon icon={faCircleNotch} /></div>;
    const buttonText = isLoading ? loadingIcon : iTextWrapper('letsGo');
    let createAccText = iTextWrapper('createAccount');
    if (majorPlan === 'hybrid') {
      createAccText = iTextWrapper('createAccountHybrid');
    } else if (majorPlan === 'project') {
      createAccText = iTextWrapper('createAccountProject');
    }
    content =
      <div>
        <div className="content-header">{createAccText}</div>
        <form onSubmit={handleDataSubmit} autoComplete="off">
          <div>
            <div className="email-container relative-container">
              <div className="label">{iTextWrapper('businessEmail')}</div>
              {emailChecked  && <i className="fa-wrapper">{checkedIcon}</i>}
              {!emailChecked && submitted && <i className="fa-wrapper error">{timesIcon}</i>}
              <input type="email" onChange={validateEmail} id="email-input" name="email" value={email} required className="form-control"></input>
            </div>
            <div className="company-container relative-container">
              <div className="label">{iTextWrapper('companyName')}</div>
              {companyChecked  && <i className="fa-wrapper">{checkedIcon}</i>}
              {!companyChecked && submitted && <i className="fa-wrapper error">{timesIcon}</i>}
              <input type="text" onChange={validateCompany} id="company-input" name="company" required className="form-control"></input>
            </div>
            {
              (majorPlan === 'hybrid' || majorPlan === 'project') && <div className="domain-container relative-container">
                <div className="label phone-label">{iTextWrapper('phoneNr')}</div>
                {phoneNrChecked  && <i className="fa-wrapper">{checkedIcon}</i>}
                {!phoneNrChecked && submitted && <i className="fa-wrapper error">{timesIcon}</i>}
                <Tippy theme={'pulse'} content={teleLabel} hideOnClick={true} trigger={'focus'} placement={'right'} touch={false} popperOptions={popperOptions}>
                  <input type="text" onChange={validatePhoneNr} id="phone-input" name="phone" required className="form-control" maxLength="50"></input>
                </Tippy>
              </div>
            }
            {
              majorPlan !== 'project' && <div className="domain-container relative-container">
                <div className="label">{iTextWrapper('workspaceName')}</div>
                <div className={`sub-label ${hasError ? 'error' : ''}`}>
                  {workspaceLabel}
                </div>
                {workspaceChecked  && <i className="fa-wrapper">{checkedIcon}</i>}
                {!workspaceChecked && submitted && <i className="fa-wrapper error">{timesIcon}</i>}
                <Tippy theme={'pulse'} content={workspaceLabelTooltip} hideOnClick={true} trigger={'focus'} placement={'right'} touch={false} popperOptions={popperOptions}>
                  <input type="text" onChange={checkWorkspaceAvailable} id="domain-input" name="domain" required className="form-control" maxLength="50"></input>
                </Tippy>
              </div>
            }
            <div className="name-container">
              <div className="firstName-container relative-container">
                <div className="label">{iTextWrapper('firstname')}</div>
                {firstNameChecked  && <i className="fa-wrapper">{checkedIcon}</i>}
                {!firstNameChecked && submitted && <i className="fa-wrapper error">{timesIcon}</i>}
                <input type="text" onChange={(e) => validateName(e, true)} id="firstName-input" name="firstName" value={firstName} required className="form-control"></input>
              </div>
              <div className="secondName-container relative-container">
                <div className="label">{iTextWrapper('secondname')}</div>
                {secondNameChecked  && <i className="fa-wrapper">{checkedIcon}</i>}
                {!secondNameChecked && submitted && <i className="fa-wrapper error">{timesIcon}</i>}
                <input type="text" onChange={(e) => validateName(e, false)} id="secondName-input" name="secondName" value={secondName} required className="form-control"></input>
              </div>
            </div>
            <div className="btn-container">
              <div className="sub-label">{iTextWrapper('tosAgreement')}</div>
              <button type="submit" disabled={btnDisabled}>{buttonText}</button>
            </div>
          </div>
        </form>
      </div>;
  }

  if (view === 1) {
    header = majorPlan === 'project' ? iTextWrapper('systemPrepProject') : iTextWrapper('systemPrep', [getLink()]);
    content =
      <div>
        <div className="content-header vertical-padding">{majorPlan === 'project' ? iTextWrapper('systemPrepInfoProject', [phoneNrChecked]) : iTextWrapper('systemPrepInfo', [userEmail])}</div>
        <div className="done-icon"><MailIcon/></div>
        <div className="content-header smaller vertical-padding">{iTextWrapper('emailSentInfo')}</div>
      </div>;
  }

  if (postReqFailed) {
    const bugIcon = <FontAwesomeIcon icon={faBug} />;
    const headerText = `${postReqFailed} ${iTextWrapper('errorStatus', [], true)}`;
    header = <h1>{headerText}</h1>;
    content =
    <div>
      <div className="content-header vertical-padding">{iTextWrapper('errorMessage')}</div>
      <div className="done-icon">{bugIcon}</div>
    </div>;
  }

  return (
    <div>
      <div className="main-title-container">
        {header}
        {!postReqFailed && view === 0 && ['premium', 'hybrid_base', 'hybrid_premium', 'hybrid_enterprise'].includes(plan) && <div className="sub-header">{iTextWrapper('subHeaderPrem')}</div>}
      </div>
      <div className="contentBox">
        {content}
      </div>
      <Footer currentView={view}/>
    </div>
  );
};

export default App;
